import request from '@/request'
export const upload = (data = {}) => {
    return request({
      url: '/admin/system/upload',
      method: 'post',
      data
    })
}

export const getWebsiteInfo = (data = {}) => {
  return request({
    url: '/admin/system/get_website_info',
    method: 'get',
    params: data
  })
}

export const updateWebsite = (data = {}) => {
  return request({
    url: '/admin/system/update_website',
    method: 'post',
    data
  })
}

export const getSolutions = (data = {}) => {
  return request({
    url: '/admin/system/get_solutions',
    method: 'get',
    params: data
  })
}

export const getPermissions = (data = {}) => {
  return request({
    url: '/admin/system/get_permissions',
    method: 'get',
    params: data
  })
}