<template>
    <div class="flex_layout inner">
        <div class="flex_top">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-button class="primary btn" @click="update('add')">Add</a-button>
                    </div>
                </a-space>
            </div>
        </div>
        <div class="flex_body">
            <a-spin :spinning="spinning">
               <a-table :dataSource="dataSource" :columns="columns">
                   <template #action="{ record }">
                       <a-space>
                           <a-button class="primary btn" @click="update('edit', record)" size="small" type="primary">Edit</a-button>
                           <a-popconfirm
                                title="Are you sure delete this?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="() => remove(record.brand_id)"
                            >
                               <a-button danger size="small">Delete</a-button>
                           </a-popconfirm>
                       </a-space>
                   </template>
                   <template #logo="{ record }">
                       <img :src="record.logo" style="margin: 0" class="brand_logo" />
                   </template>
               </a-table>
            </a-spin>
        </div>
        <a-drawer
            title="Brand"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f9f9f9'}"
            >
               <div class="container">
                   <a-spin :spinning="spinning">
                    <div class="form">
                        <div class="form_title">Brand</div>
                        <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item label="Brand name">
                                <a-input v-model:value="formData.brand_name" placeholder="Brand name" />
                            </a-form-item>                                                  
                            <a-form-item label="Brand logo">
                                <img :src="brand_logo" class="brand_logo">
                                <a-upload :customRequest="(file) => customRequest(file.file)" :showUploadList="false">
                                    <a-button>
                                        <component :is="$antIcons['UploadOutlined']" />
                                        Upload
                                    </a-button>
                                </a-upload>
                            </a-form-item>
                            <a-form-item label="Brand description">
                                <a-textarea :rows="5" v-model:value="formData.brand_description" placeholder="Brand description" />
                            </a-form-item>
                            <a-form-item label="Product class">
                                 <a-select
                                    ref="select"
                                    v-model:value="formData.class_id"
                                    :options="productClassList"
                                    placeholder="Select product class"
                                >
                                </a-select>
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                <a-button type="primary" @click="updateBrandHandler">Submit</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                   </a-spin>
               </div>
        </a-drawer>
    </div>
</template>
<script>
const columns = [
    {
        title: 'Brand id',
        dataIndex: 'brand_id',
        key: 'brand_id',
    },
    {
        title: 'Brand name',
        dataIndex: 'brand_name',
        key: 'brand_name',
    },
    {
        title: 'Brand logo',
        key: 'brand_logo',
        slots: { customRender: 'logo' },
    },
    {
        title: 'Class',
        dataIndex: 'class_name',
        key: 'class_name',
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
import { message } from 'ant-design-vue'
import { getBrandList, updateBrand, removeBrand } from '@/apis/brand'
import { getProductClassList } from '@/apis/productClass'
import { upload } from '@/apis/system'
export default {
    name: 'Product',
    data(){
        return {
            dataSource: [],
            columns,
            visible: false,
            formData: {},
            brand_logo: '',
            labelCol: { span: 6 },
            wrapperCol: { span: 12 },
            spinning: false,
            productClassList: []
        }
    },
    methods: {
        update(actionType, brandInfo){
            if(actionType == 'add'){
                this.initParams()
            }
            if(actionType == 'edit'){
                this.formData = brandInfo
                this.brand_logo = brandInfo.logo
                this.formData.action_type = actionType
            }
            this.visible = true
        },
        async updateBrandHandler(){
            if(this.formData.class_name == '') return message.error('Please input class name')
            this.spinning = true
            const res = await updateBrand(this.formData)
            this.spinning = false
            if(res){
                this.visible = false
                this.getBrandListHandler()
            } 
        },
        async remove(brand_id){
            this.spinning = true
            const res = await removeBrand({brand_id})
            this.spinning = false
            if(res){
                this.getBrandListHandler()
            } 
        },
        onClose(){
            this.visible = false
        },
        async getBrandListHandler(){
            this.spinning = true
            const res = await getBrandList()
            this.spinning = false
            if(res){
                this.dataSource = res.list  
            }
        },
        async customRequest(file){
            this.spinning = true
            const res = await upload(file)
            this.spinning = false
            if(res){
                this.formData.brand_logo = res.name
                this.brand_logo = res.file_name
            }
        },
        async getProductClassListHandler(){
            const res = await getProductClassList()
            if(res){
                this.productClassList = res.list.map(r => {
                    r.label = r.class_name
                    r.value = r.class_id
                    return r
                })
                this.formData.class_id = this.productClassList.length == 0 ? undefined :this.productClassList[0].class_id
            }
        },
        initParams(){
            this.formData = {
                brand_id: 0,
                brand_name: '',
                brand_description: '',
                brand_logo: '',
                logo: '',
                class_id: undefined,
                action_type: 'add'
            }
        }
    },
    created(){
        this.getBrandListHandler()
        this.getProductClassListHandler()
    }
}
</script>
<style lang="stylus" scoped>
.brand_logo
    display block
    margin-bottom 10px
    width 100px
</style>